<template>
	<div class="operationFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md4 lg2>
				<pui-field :label="this.$t('header.operation.portname')" :value="getPortName"></pui-field>
			</v-flex>
			<v-flex xs12 md4 lg2>
				<pui-field :label="this.$t('header.operation.portcallnumber')" :value="getPortCallNumber"></pui-field>
			</v-flex>
			<v-flex xs12 md4 lg2>
				<pui-field :label="this.$t('header.operation.stopnum')" :value="getStopNum"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.operation.vesselname')" :value="getVesselName"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.operation.berthname')" :value="getBerthName"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'operationFormHeader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'operation',
			berthname: null
		};
	},
	computed: {
		getPortName() {
			return this.model && this.model.portname ? this.model.portname : '';
		},
		getPortCallNumber() {
			return this.model && this.model.portcallnumber ? this.model.portcallnumber : '';
		},
		getStopNum() {
			return this.model && this.model.stopnum ? this.model.stopnum : '';
		},
		getVesselName() {
			return this.model && this.model.vesselname ? this.model.vesselname : '';
		},
		getBerthName() {
			return this.model && this.model.berthname ? this.model.berthname : '';
		}
	}
};
</script>
