<template>
	<div class="pui-form">
		<operation-modals :modelName="modelName"></operation-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<operation-form-header :modelPk="modelPk"></operation-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.operation.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#documents'" v-if="!isCreatingElement && showDocuments">{{ $t('pui9.documents.tab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-row dense class="pui-form-layout">
						<v-col cols="12">
							<pui-field-set :title="$t('form.operation.operationdata')">
								<v-row dense>
									<v-col cols="2">
										<pui-select
											:id="`portname-${modelName}`"
											:attach="`portname-${modelName}`"
											:label="this.$t('form.operation.portname')"
											toplabel
											clearable
											required
											:disabled="formDisabled || !enablePort || !isCreatingElement"
											v-model="model"
											modelName="port"
											:itemsToSelect="itemsToSelectPort"
											:modelFormMapping="{ id: 'portid' }"
											:key="'portid_' + portidKey"
											itemValue="id"
											itemText="portname"
											reactive
											:fixedFilter="filterByUserPortsAndAuthority"
										></pui-select>
									</v-col>
									<v-col cols="8">
										<pui-select
											:id="`visitid-${modelName}`"
											:attach="`visitid-${modelName}`"
											:label="$t('form.operation.portcallnumber')"
											toplabel
											clearable
											required
											:disabled="!isCreatingElement || !enableVisit"
											v-model="model"
											modelName="visit"
											:itemsToSelect="itemsToSelectVisits"
											:modelFormMapping="{ id: 'visitid' }"
											:itemValue="['id']"
											:itemText="
												(item) =>
													`${item.portcallnumber} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
														item.eta,
														'DD/MM/YYYY HH:mm'
													)} - ${this.$dateTimeUtils.getLocalFormattedDate(item.etd, 'DD/MM/YYYY HH:mm')} - ${item.status}`
											"
											:order="{ eta: 'asc' }"
											:fixedFilter="portidFixedFilter"
											:key="'portid_' + portidKey"
											reactive
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="10">
										<pui-select
											:id="`stopid-${modelName}`"
											:attach="`stopid-${modelName}`"
											:label="$t('form.operation.stopnum')"
											toplabel
											clearable
											:disabled="formDisabled || !enableStop || !isCreatingElement"
											v-model="model"
											modelName="stop"
											:itemsToSelect="itemsToSelectStops"
											:modelFormMapping="{ id: 'stopid' }"
											itemValue="id"
											:itemText="(item) => getStopItemText(item)"
											:order="{ num: 'asc' }"
											:fixedFilter="visitidFixedFilter"
											:key="'stopid_' + visitidKey"
											reactive
											detailComponentName="stop-form"
											detailModelName="stop"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="3">
										<pui-number-field
											:id="`operationcode-${modelName}`"
											v-model="model.operationcode"
											:label="$t('form.operation.operationcode')"
											:disabled="formDisabled || !model.operationcode"
											required
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-col>
									<v-col cols="3">
										<pui-select
											:id="`typeid-${modelName}`"
											:attach="`typeid-${modelName}`"
											:label="$t('form.operation.operationtype')"
											toplabel
											clearable
											required
											:disabled="formDisabled"
											v-model="model"
											modelName="operationtype"
											:itemsToSelect="itemsToSelectOperationtype"
											:modelFormMapping="{ operationtypeid: 'typeid' }"
											itemValue="operationtypeid"
											itemText="operationtypename"
											:order="{ operationtypename: 'asc' }"
											reactive
										></pui-select>
									</v-col>
									<v-col cols="6">
										<pui-date-field
											:id="`requestdate-${modelName}`"
											:label="$t('form.operation.requestdate')"
											v-model="model.requestdate"
											toplabel
											:disabled="formDisabled"
											time
										></pui-date-field>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="6">
										<pui-date-field
											:id="`startdate-${modelName}`"
											:label="$t('form.operation.startdate')"
											v-model="model.startdate"
											toplabel
											:disabled="formDisabled"
											required
											time
										></pui-date-field>
									</v-col>
									<v-col cols="6">
										<pui-date-field
											:id="`enddate-${modelName}`"
											:label="$t('form.operation.enddate')"
											v-model="model.enddate"
											toplabel
											:disabled="formDisabled"
											time
										></pui-date-field>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4">
										<pui-select
											:id="`name-${modelName}`"
											:attach="`name-${modelName}`"
											:label="$t('form.operation.loadtypename')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="operationloadtype"
											:itemsToSelect="itemsToSelectLoadType"
											:modelFormMapping="{ operationloadtypeid: 'loadtype' }"
											:itemValue="['operationloadtypeid']"
											:itemText="(item) => `${item.operationloadtypecode} - ${item.name}`"
											:order="{ operationloadtypecode: 'asc' }"
											reactive
										></pui-select>
									</v-col>
									<v-col cols="1">
										<pui-text-field
											:id="`quantity-operation`"
											v-model="model.quantity"
											:label="$t('form.operation.quantity')"
											:disabled="formDisabled || !this.showQuantity"
											toplabel
											maxlength="17"
										></pui-text-field>
									</v-col>
									<v-col cols="2">
										<pui-select
											:id="`unittype-${modelName}`"
											:attach="`unittype-${modelName}`"
											:label="$t('form.operation.unittypename')"
											toplabel
											clearable
											:disabled="formDisabled || !this.showQuantity"
											v-model="model"
											modelName="operatingunittype"
											:itemsToSelect="itemsToSelectUnittype"
											:modelFormMapping="{ operatingunittypecode: 'unittype' }"
											itemValue="operatingunittypecode"
											itemText="operatingunittypename"
											:order="{ operatingunittypename: 'asc' }"
											:fixedFilter="filterUnitTypes"
											reactive
										></pui-select>
									</v-col>
									<v-col cols="5">
										<pui-select
											:id="`companyid-${modelName}`"
											:attach="`companyid-${modelName}`"
											:label="$t('form.operation.companyid')"
											v-model="model"
											modelName="company"
											toplabel
											clearable
											:disabled="formDisabled"
											:itemsToSelect="itemsToSelectStevedoreCompany"
											:modelFormMapping="{ id: 'companyid' }"
											itemValue="id"
											itemText="name"
											:fixedFilter="filterStevedoreCompany"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12">
										<pui-text-area
											:id="`place-operation`"
											v-model="model.place"
											:label="$t('form.operation.place')"
											:disabled="formDisabled"
											toplabel
											maxlength="100"
										></pui-text-area>
									</v-col>
								</v-row>
							</pui-field-set>
							<pui-field-set
								:title="$t('form.operation.resourcesdata')"
								v-if="!isCreatingElement && !isModalDialog && has_READ_RESOURCEALLOCATION"
							>
								<pui-master-detail
									componentName="resourceallocationgrid"
									:parentModelName="modelName"
									:parentPk="pk"
									:parentPkChildFk="{ operationid: 'operation_id' }"
									:formDisabled="formDisabled"
									:modalDialog="isModalDialog"
									:parentModel="model"
								></pui-master-detail>
							</pui-field-set>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" :value="`documents`" v-if="!isCreatingElement && showDocuments">
					<pui-master-detail componentName="PuiDocumentGrid" :parentModelName="modelName" :parentPk="pk"></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="saveAndUpdate" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import operationModals from './OperationModals.vue';
import OperationFormHeader from './OperationFormHeader.vue';

export default {
	name: 'operationform',
	mixins: [PuiFormMethodsMixin],
	components: {
		'operation-modals': operationModals,
		OperationFormHeader
	},
	data() {
		return {
			modelName: 'operation',
			portidKey: 0,
			portidFixedFilter: null,
			visitidKey: 0,
			visitidFixedFilter: null,
			showQuantity: false,
			filterUnitTypes: {
				groups: [],
				groupOp: 'and',
				rules: []
			},
			stevedoreCompanyType: 4,
			filterStevedoreCompany: {
				groups: [],
				groupOp: 'and',
				rules: []
			},
			enableVisit: false,
			enableStop: false,
			enablePort: false,
			tabmodel: 'tab0'
		};
	},
	props: {
		pk: {
			type: [Object, String],
			required: true
		}
	},
	methods: {
		afterGetData() {
			this.getStevedoreCompanyList();
			if (this.isCreatingElement && Object.keys(this.$attrs).length > 0) {
				if (this.$attrs.visitpk) {
					this.model.visitid = JSON.parse(atob(this.$attrs.visitpk)).id;
					this.enableVisit = false;
					if (!this.model.portid) {
						this.getPortIdFromVisit();
						if (this.model.portid) {
							this.enablePort = false;
						}
					}
				}
				if (this.isCreatingElement && this.$attrs.stoppk) {
					this.model.stopid = JSON.parse(atob(this.$attrs.stoppk)).id;
					this.enableStop = false;
					if (!this.model.visitid) {
						this.getVisitIdFromStop();
						if (this.model.visitid) {
							this.enableVisit = false;
						}
					}
				}
			}
			this.getPortIdFromVisit();
		},
		getStevedoreCompanyList() {
			const userCompanyId = this.$store.state.session.properties.COMPANY_ID;
			const userCompanyType = this.$store.state.session.properties.COMPANY.companytypeid;

			if (userCompanyType && userCompanyType === this.stevedoreCompanyType) {
				this.filterStevedoreCompany.rules = [{ field: 'id', op: 'eq', data: userCompanyId }];
			} else {
				this.filterStevedoreCompany.rules = [{ field: 'companytypeid', op: 'eq', data: this.stevedoreCompanyType }];
			}
		},
		getNextOperationCodeNum() {
			if (!this.model.operationcode && this.model.visitid && this.model.stopid) {
				const opts = {
					model: 'operation',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{ field: 'visitid', op: 'eq', data: this.model.visitid },
							{ field: 'stopid', op: 'eq', data: this.model.stopid }
						]
					},
					order: [{ column: 'operationcode', direction: 'desc' }]
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					if (response.data.data.length && response.data.data[0].operationcode) {
						this.model.operationcode = response.data.data[0].operationcode + 1;
					} else {
						this.model.operationcode = 1;
					}
				});
			}
		},
		getUnitTypes(loadTypeId) {
			const opts = {
				model: 'operationloadtype',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'operationloadtypeid', op: 'eq', data: loadTypeId }]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data.length) {
					const unitTypes = response.data.data[0].unittype;
					if (unitTypes) {
						let unitTypeList = [];
						if (unitTypes.includes(',')) {
							unitTypeList = unitTypes.split(',');
						} else {
							unitTypeList = unitTypes;
						}
						this.filterUnitTypes.rules = [{ field: 'operatingunittypecode', op: 'in', data: unitTypeList }];
					} else {
						this.resetFilter();
					}
				} else {
					this.resetFilter();
				}
			});
		},
		resetFilter() {
			this.filterUnitTypes.rules = [];
		},
		getVisitIdFromStop() {
			const opts = {
				model: 'stop',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'id', op: 'eq', data: this.model.stopid }]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data.length) {
					this.model.visitid = response.data.data[0].visitid;
					if (this.model.visitid) {
						this.getNextOperationCodeNum();
					}
					this.model.portid = response.data.data[0].portid;
				}
			});
		},
		getPortIdFromVisit() {
			const opts = {
				model: 'visit',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'id', op: 'eq', data: this.model.visitid }]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data.length) {
					this.model.portid = response.data.data[0].portid;
				}
			});
		},
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;
			console.log(item);

			return `${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(startdate, 'DD/MM/YYYY HH:mm')}  ${
				enddate ? '-' + this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm') : ''
			} - ${item.status} - ${item.location}`;
		}
	},
	computed: {
		itemsToSelectOperationtype() {
			return [{ operationtypeid: this.model.typeid }];
		},
		itemsToSelectLoadType() {
			return [{ operationloadtypeid: this.model.loadtype }];
		},
		itemsToSelectUnittype() {
			return [{ operatingunittypecode: this.model.unittype }];
		},
		itemsToSelectPort() {
			return [{ id: this.model.portid }];
		},
		itemsToSelectStevedoreCompany() {
			return [{ id: this.model.companyid }];
		},
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			const userPortAuthority = window.localStorage.getItem('workingPortAuthorityId');
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: userPortAuthority }
				]
			};
		},
		itemsToSelectStevedoreCompany() {
			return [{ id: this.model.companyid }];
		},
		itemsToSelectVisits() {
			return [{ id: this.model.visitid }];
		},
		visitStatusCodes() {
			return this.$store.getters.getActiveVisitStatusid;
		},
		itemsToSelectStops() {
			return [{ id: this.model.stopid }];
		},
		has_READ_RESOURCEALLOCATION() {
			return this.$store.getters.hasFunctionality('READ_RESOURCEALLOCATION');
		}
	},
	created() {},
	watch: {
		'model.portid'(newVal) {
			if (Object.keys(this.$attrs).length === 0) {
				if (newVal != null) {
					this.portidFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'portid', op: 'eq', data: newVal },
							{ field: 'statusid', op: 'in', data: this.visitStatusCodes }
						]
					};
					this.enableVisit = true;
				} else {
					this.portidFixedFilter = null;
					this.visitidFixedFilter = null;
					this.movementVisitidFixedFilter = null;

					if (this.isCreatingElement) {
						this.model.portid = null;
						this.model.stopid = null;
					}

					this.model.movementid = null;
					this.enablePort = true;
				}
				this.portidKey += 1;
			}
		},
		'model.visitid'(newVal) {
			if (newVal) {
				this.visitidFixedFilter = { groups: [], groupOp: 'and', rules: [{ field: 'visitid', op: 'eq', data: newVal }] };
				if (Object.keys(this.$attrs).length === 0) {
					this.enableStop = true;
				} else {
					if (!this.model.stopid) {
						this.enableStop = true;
					}
				}
			} else {
				this.visitidFixedFilter = null;
			}
			this.visitidKey += 1;
		},
		'model.stopid'(newVal) {
			if (newVal) {
				this.getNextOperationCodeNum();
			}
		},
		'model.loadtype'(newVal) {
			if (newVal) {
				this.getUnitTypes(newVal);
				this.showQuantity = true;
			} else {
				this.showQuantity = false;
			}
		}
	}
};
</script>
